
export default {
  name: 'OrganismCreator',
  props: {
    creatorData: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    }
  }
}
